import React, { useEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { setConfig } from '../actions/config';
import apiGet from '../actions/api';
import { lang } from './trans';

const AppContainer = styled.div`
  min-height: 100vh;
  @media (min-width: 768px) {
    background-color: ${(props) => props.theme.appContainerBg}!important;
  }
`;

export default function AppInitialization({ children }) {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);

  useEffect(() => {
    apiGet({ url: '/config.json' }).then((resp) => dispatch(setConfig(resp)));
    const { hash } = window.location;
    if (hash && hash.substring(0, 1) === '#') {
      window.location.href = hash.substring(1, hash.length);
    }
  }, [dispatch]);

  const linkData = useMemo(
    () =>
      config && config.theme
        ? [
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: config.theme.faviconapp,
          },
          { rel: 'apple-touch-icon', href: config.theme.appleicontouch },
          { rel: 'apple-touch-icon', sizes: '72x72', href: config.theme.appleicontouch },
        ]
        : [],
    [config]
  );

  const htmlAttributes = useMemo(() => ({ lang }), []) // lang

  if (!config || Object.keys(config).length === 0) {
    return null;
  }

  return (
    <>
      <Helmet link={linkData} htmlAttributes={htmlAttributes} />
      <ThemeProvider theme={config.theme}>
        <AppContainer className="app-container">{children}</AppContainer>
      </ThemeProvider>
      {/* <Footer /> */}
    </>
  );
}
