import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import backendQuery from '../actions/backendQuery';
import { setFooterEntries } from '../actions/footer';
import NewsletterSignUp from '../pages/NewsletterSignUp';

const FooterContainer = styled.div`
  height: 64px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  line-height: 64px;
  background-color: ${(props) => props.theme.FooterBg};
  color: ${(props) => props.theme.Footer};
  ol li {
    margin-right: 20px;
    list-style-type: none;
  }
`;

const FooterBar = styled.div`
  overflow: hidden;
  text-align: center;
  background-color: ${(props) => props.theme.FooterBarBg};
  ol li {
    display: inline-block;
  }
`;

const FooterBarXs = styled.div`
  text-align: center;
  overflow: hidden;
  ol li {
    display: inline-block;
  }

  background-color: ${(props) => props.theme.FooterBarXsBg};
`;

export default function Footer() {
  const footer = useSelector(state => state.footer)
  const { theme, footerURL, showNewsletterPopup } = useSelector(state => state.config)
  const dispatch = useDispatch()
  useEffect(() => {
    backendQuery({ url: footerURL, RFMscript: 'RESTLegal' }).then((resp) =>
      dispatch(setFooterEntries(resp.data[0]))
    );
  }, [footerURL, dispatch]);
  const footerLinks = useMemo(() => (
    <ol style={{ margin: 0 }}>
      <li>
        <span className="hidden-xs">&copy; 2025 carhs.training GmbH</span>
      </li>
      <li>
        <Link to="/about/" style={{ color: theme.FooterA }}>
          <b>{footer.data.f382t_Web_Ueber_Titel}</b>
        </Link>
      </li>
      <li>
        <Link to="/impressum/" style={{ color: theme.FooterA }}>
          {footer.data.f380t_Web_Impressum_Titel}
        </Link>
      </li>
      <li>
        <Link to="/privacypolicy/" style={{ color: theme.FooterA }}>
          {footer.data.f384t_Web_Datenschutz_Titel}
        </Link>
      </li>
      <li>
        <Link to="/legalnotice/" style={{ color: theme.FooterA }}>
          {footer.data.f386t_Web_Rechtlich_Titel}
        </Link>
      </li>
    </ol>
  ), [footer.data.f380t_Web_Impressum_Titel, footer.data.f382t_Web_Ueber_Titel, footer.data.f384t_Web_Datenschutz_Titel, footer.data.f386t_Web_Rechtlich_Titel, theme.FooterA]);

  if (!footer || !footer.data) {
    return null;
  }

  return (
    <div>
      <FooterContainer>
        <div style={{ padding: 0 }}>
          <FooterBar className="hidden-xs">{footerLinks}</FooterBar>
          <FooterBarXs className="visible-xs">{footerLinks}</FooterBarXs>
          <span
            style={{ backgroundColor: theme.CopyrightXsBg, textAlign: 'center' }}
            className="visible-xs"
          >
            &copy; 2025 carhs.training GmbH
          </span>
        </div>
      </FooterContainer>
      {showNewsletterPopup ? <NewsletterSignUp /> : null}
    </div>
  );
}
