import React, { useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import trans from './trans';
import Section from './Section';
import styled from 'styled-components'

const TableCondensed = styled.table`
  margin-bottom: 0;
  & > tbody > tr > th {
    background-color: #fff;
    border-top: 2px solid #eeeff2;
  }
  & > tbody > tr > td:first-child {
    padding: 10px 20px;
  }
  & > tbody > tr:nth-child(odd) > td {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  & > tbody > tr:nth-child(even) > td {
    background-color: grey;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f2f3f6;
    border-top: 1px solid #eeeff2;
    border-bottom: 1px solid #eeeff2;
  }
`;

const COMPANY_COLUMN_WIDTH = '21rem';
const DATE_COLUMN_WIDTH = '11rem';

const COLUMNS_CONFIGS = {
  A11: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.A11' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.authorsource',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
    col3: {
      property: 'f036t_Gueltigkeitsdatum_ISO',
      label: 'objectlistheader.publicationdate',
      type: 'date',
      className: 'hidden-xs tr',
      width: DATE_COLUMN_WIDTH,
    },
  },
  B04: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B04' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.author',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
    col2: {
      property: 'f038t_Erscheinungsdatum_ISO',
      label: 'objectlistheader.publishedon',
      type: 'date',
      className: 'hidden-xs',
      width: DATE_COLUMN_WIDTH,
    },
    col3: {
      property: 'f036t_Gueltigkeitsdatum_ISO',
      label: 'objectlistheader.validfrom',
      type: 'date',
      className: 'hidden-xs tr',
      width: DATE_COLUMN_WIDTH,
    },
  },
  B14: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B14' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.host',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
    col3: {
      property: 'f036t_Gueltigkeitsdatum_ISO',
      label: 'objectlistheader.eventdate',
      type: 'date',
      className: 'hidden-xs tr',
      width: DATE_COLUMN_WIDTH,
    },
  },
  B01: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B01' },
    col3: {
      property: 'f036t_Gueltigkeitsdatum_ISO',
      label: 'objectlistheader.validfrom',
      type: 'date',
      className: 'hidden-xs tr',
      width: DATE_COLUMN_WIDTH,
    },
  },
  B11: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B11' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.manufacturer',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
  },
  B02: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B02' },
  },
  B10: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B10' },
  },
  B06: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B06' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.manufacturer',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
  },
  B07: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B07' },
  },
};

// compare function for the sort call in renderItems
// compareFunction: A function that defines an alternative sort order.
// The function should return a negative, zero, or positive value, depending on the arguments

const date_fields = ["f038t_Erscheinungsdatum_ISO"]


function compareItems(first, second, field, desc, sortType, currType) {
  if (sortType !== currType) {
    field = 'n005t_Bezeichnung';
    desc = false;
  }
  if (!first.element || !first.element[field] || !first.element[field].toLowerCase) {
    return desc ? 1 : -1;
  }
  if (!second.element || !second.element[field] || !second.element[field].toLowerCase) {
    return desc ? -1 : 1;
  }
  const firstValueRaw = first.element[field];
  const firstValue = date_fields.includes(field) ? firstValueRaw : firstValueRaw.toLowerCase()
  const secondValueRaw = second.element[field]
  const secondValue = date_fields.includes(field) ? secondValueRaw : secondValueRaw.toLowerCase()
  // console.log('first', firstValueRaw, firstValue, 'second', secondValueRaw, secondValue)

  if (firstValue === secondValue) {
    return 0;
  }
  if (firstValue < secondValue) {
    return desc ? 1 : -1;
  }
  if (firstValue > secondValue) {
    return desc ? -1 : 1;
  }
  return 0;
}


export default function SearchObjectListTableContent({ localResults }) {
  const location = useLocation();
  const locatioSearchParsedType = useMemo(() => {
    const query = querystring.parse(location.search);
    return query.type;
  }, [location.search]);
  const previousUrl = useMemo(
    () => `${location.pathname}${location.search}`,
    [location.pathname, location.search]
  );
  return <TableCondensed className="table table-condensed">
    <tbody>
      {localResults.map((type) => <RenderTable
        key={type.type}
        type={type.type}
        desc={type.desc}
        list={type.list}
        searchField={type.searchField}
        location={location}
        locatioSearchParsedType={locatioSearchParsedType}
        previousUrl={previousUrl}
      />)}
    </tbody>
  </TableCondensed>
}

function SortButton({ query, field, type }) {
  if (query.type === type && query.field === field) {
    if (query.desc && query.type === type && query.field === field) {
      return (
        <span
          style={{ fontSize: 12 }}
          className="glyphicon glyphicon-arrow-down"
          aria-hidden="true"
        />
      );
    }
    return (
      <span style={{ fontSize: 12 }} className="glyphicon glyphicon-arrow-up" aria-hidden="true" />
    );
  }
  if (query.type === undefined && field === 'n005t_Bezeichnung') {
    return (
      <div>
        <span
          style={{ fontSize: 12 }}
          className="glyphicon glyphicon-arrow-down"
          aria-hidden="true"
        />
        <span
          style={{ fontSize: 12 }}
          className="glyphicon glyphicon-arrow-up"
          aria-hidden="true"
        />
      </div>
    );
  }
  return null;
}

function RenderColValue({ coltype, value }) {
  const { formatDate } = useIntl();

  if (coltype !== 'date') {
    return value;
  }
  if (!value) {
    return '';
  }
  // console.log('val', value)
  if (value.indexOf('[') === 0) {
    return value;
  }
  try {
    return formatDate(value, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  } catch (e) {
    console.log('Error Formating as Date', value);
    return 'Ungültiges Datum';
  }
}

function linkColorDecide(f025b_Aktuell) {
  if (f025b_Aktuell === '0') {
    return '#EE7600';
  }
  if (f025b_Aktuell === '2') {
    return '#009051';
  }
  return '#34434A'
}

function getNewQuery(query, type, field, key) {
  const newQuery = { ...query, type, field, _k: key };
  delete newQuery.desc;
  if (type === query.type && !query.desc) {
    newQuery.desc = true;
  }
  if (query.type === undefined && field === 'n005t_Bezeichnung') {
    newQuery.desc = true;
  }
  return querystring.stringify(newQuery);
};


function RenderColHeader({ col, query, type, _k, pathname }) {
  const navigate = useNavigate();
  const coldata = COLUMNS_CONFIGS[type][col]
  if (!coldata) {
    return <th className="hidden-xs" style={{ width: '2rem', color: 'black' }} />
  }
  const { className, width, label, property } = coldata
  return <th
    className={classNames('pointer tl', className)}
    style={{
      color: 'black',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      verticalAlign: 'middle',
      hyphens: 'auto',
      width: width,
    }}
    onClick={() =>
      navigate({ pathname, search: getNewQuery(query, type, property, _k) })
    }
  >
    {label ? trans(label) : property}
    <SortButton field={property} type={type} query={query} />
  </th>
}



function RenderTable({
  type,
  list,
  desc,
  searchField,
  locatioSearchParsedType,
  location,
  previousUrl,
}) {
  const sortedLst = useMemo(
    () =>
      list.sort((a, b) =>
        compareItems(a, b, searchField, desc, locatioSearchParsedType, type)
      ),
    [locatioSearchParsedType, desc, list, searchField, type]
  );

  const navigate = useNavigate();

  const { search, pathname, key } = location

  const query = querystring.parse(search);

  if (!COLUMNS_CONFIGS[type]) {
    const colspancount = "4"
    return (<React.Fragment>
      <tr>
        <th
          colSpan={colspancount}
          className="pointer tl"
          style={{ color: 'black', display: 'flex' }}
          onClick={() => navigate({ pathname, search: getNewQuery(query, type, 'n005t_Bezeichnung', key) })}
        >
          {type}
          <SortButton field="n005t_Bezeichnung" type={type} query={query} />
        </th>
      </tr>
      {sortedLst.map(({ element, db }, key) => (<tr key={`object-list-item-${element.id + key}`}>
        <td colSpan={colspancount} className="tl">
          <Link
            style={{ color: linkColorDecide(element.f025b_Aktuell) }}
            to={{
              pathname: `/object/${element.f001t_Typ}/${element.a001t_ID_Objekt}/${db}`,
              search: querystring.stringify({ prev: previousUrl }),
            }}
          >
            {element.n005t_Bezeichnung}
          </Link>
        </td>
      </tr>))}
    </React.Fragment>)
  }

  const { col0, col1, col2, col3 } = COLUMNS_CONFIGS[type];

  return <React.Fragment>
    <tr>
      <th
        className={classNames('pointer tl', col0.className)}
        onClick={() =>
          navigate({ pathname, search: getNewQuery(query, type, col0.property, key) })
        }
      >
        <div style={{ display: 'flex', color: 'black', alignItems: 'center' }}>
          <Section>{col0.label ? trans(col0.label) : type} </Section>
          <SortButton field={col0.property} type={type} query={query} />
        </div>
      </th>
      <RenderColHeader col="col1" type={type} _k={key} pathname={pathname} query={query} />
      <RenderColHeader col="col2" type={type} _k={key} pathname={pathname} query={query} />
      <RenderColHeader col="col3" type={type} _k={key} pathname={pathname} query={query} />
    </tr>
    {sortedLst.map(({ element, db }, key) => (
      <tr key={`object-list-item-${element.id + key}`}>
        <td
          className={classNames('tl', col0.className)}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          <Link
            style={{ color: linkColorDecide(element.f025b_Aktuell) }}
            to={{
              pathname: `/object/${element.f001t_Typ}/${element.a001t_ID_Objekt}/${db}`,
              search: querystring.stringify({ prev: previousUrl }),
            }}
          >
            {element[col0.property]}
          </Link>
        </td>
        {col1 ? (
          <td
            className={classNames('tl', col1.className)}
            style={{ wordWrap: 'break-word', wordBreak: 'break-word', width: '21rem' }}
          >
            <RenderColValue coltype={col1.type} value={element[col1.property]} />
          </td>
        ) : (
          <td className="hidden-xs" />
        )}
        {col2 ? (
          <td
            className={classNames('tl', col2.className)}
            style={{ wordWrap: 'break-word', wordBreak: 'break-word', width: '10rem' }}
          >
            <RenderColValue coltype={col2.type} value={element[col2.property]} />
          </td>
        ) : (
          <td className="hidden-xs" />
        )}{col3 ? (
          <td
            className={classNames('tl', col3.className)}
            style={{ wordWrap: 'break-word', wordBreak: 'break-word', width: '10rem' }}
          >
            <RenderColValue coltype={col3.type} value={element[col3.property]} />
          </td>
        ) : (
          <td className="hidden-xs" />
        )}
      </tr>

    ))}
  </React.Fragment>
}